import { getLocalPid } from "@/libs/util";
import { bizCefrWords } from "@/api";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "CefrWords",
  props: {
    sentence: {
      type: String,
      required: true
    }
  },
  watch: {
    sentence: {
      immediate: true,
      handler(newContent) {
        this.sent = newContent;
        this.parseCefrWords();
      }
    }
  },
  data() {
    return {
      inited: false,
      store: null,
      safeMes: null,
      sent: '',
      cefrWords: null
    };
  },
  mounted() {
    console.log('CefrWords mounted');
  },
  methods: {
    init: function () {
      if (!this.inited) {
        this.store = useStore();
        this.safeMes = computed(() => this.store.state.safeMes);
        this.inited = true;
      }
    },
    parseCefrWords: function () {
      this.init();
      let obj = {
        ...this.safeMes,
        pid: getLocalPid(),
        sentence: this.sent
      };
      bizCefrWords(obj).then(res => {
        let data = res.data;
        if (data.code) return ElMessage.error(data.msg);
        this.cefrWords = data.data;
      }).catch(err => {
        console.log(err);
        return ElMessage.error("发生错误 统计CEFR词汇出错");
      });
    }
  }
};