import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "flex-direction": "column"
  }
};
const _hoisted_2 = {
  style: {
    "font-size": "large",
    "font-weight": "bolder"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cefrWords, (cefrWord, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      style: {
        "margin-top": "10px",
        "background-color": "rgb(72,116,203)",
        "padding": "10px",
        "border-radius": "10px",
        "border-color": "rgb(72,116,250)",
        "color": "white",
        "width": "50%"
      }
    }, [_createElementVNode("div", _hoisted_2, _toDisplayString(cefrWord.word), 1), _createElementVNode("div", null, "词性：" + _toDisplayString(cefrWord.partOfSpeech), 1), _createElementVNode("div", null, "课标级别：" + _toDisplayString(cefrWord.level), 1), _createElementVNode("div", null, "欧标级别：" + _toDisplayString(cefrWord.cefrLevel), 1), _createElementVNode("div", null, "应用频率：" + _toDisplayString(cefrWord.usageFrequency), 1), _createElementVNode("div", null, "考试频率：" + _toDisplayString(cefrWord.examFrequency), 1)]);
  }), 128))]);
}